import { Children, FC } from 'react';
import styles from './information.module.scss';
import { IInformationProps } from './interfaces';

export const Information: FC<IInformationProps> = ({ children }) => (
    <div className={styles.container}>
        {Children.map(children, (child, index) => (
            <>
                {child}
                {Array.isArray(children) && index + 1 !== children.length && <div className={styles.line} />}
            </>
        ))}
    </div>
);