'use client';

import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import dynamic from 'next/dynamic';
import styles from './form-with-map.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';

const Map = dynamic(() => import('@/widgets/map'), { ssr: false });
const LeadForm = dynamic(() => import('@/widgets/lead-form').then(module => module.LeadForm), { ssr: false });

export const FormWithMap = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  const forms = useHomePageStore()?.form;
  const form = forms?.[2];

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={styles.container} {...props}>
                    <div ref={ref} className={styles.map}>
                        {isInView && <Map/>}
                    </div>
                    <div className={styles.form}>
                        {isInView && <LeadForm code={form?.code} politicsFile={form?.politicsFile} title={form?.title ?? ''} titleButton={form?.titleButton ?? ''} bookletsLink={form?.bookletsLink ?? ''} description={form?.description ?? ''}/>}
                    </div>
                </motion.section>
            )}
        </AppearAnimation>
  );
};