'use client';

import { FC, useRef } from 'react';
import classNames from 'classnames';
import { motion, useInView } from 'framer-motion';
import dynamic from 'next/dynamic';
import styles from './contact-form.module.scss';
import { IContactFormProps } from '@/entities/home/contact-form/inetrfaces';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Image } from '@/shared/ui/image';

const LeadForm = dynamic(() => import('@/widgets/lead-form').then(module => module.LeadForm), { ssr: false });

export const ContactForm: FC<IContactFormProps> = ({ formIndex }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  const forms = useHomePageStore()?.form;
  const form = forms?.[formIndex];

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={classNames(styles.section, 'container mb-170')} {...props}>
                    <div ref={ref} className={styles.container}>
                        {isInView && (
                            <>
                                <div className={styles.image}>
                                    <Image src={form?.slide} alt={form?.title}/>
                                </div>
                                <div>
                                    <LeadForm
                                        politicsFile={form?.politicsFile}
                                        code={form?.code}
                                        title={form?.title ?? ''}
                                        description={form?.description ?? ''}
                                        titleButton={form?.titleButton ?? ''}
                                        bookletsLink={form?.bookletsLink ?? ''}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </motion.section>
            )}
        </AppearAnimation>
  );
};