import { FC } from 'react';
import Link from 'next/link';
import styles from './news-article.module.scss';
import { INewsArticleProps } from '@/shared/ui/news-article/interfaces';

const Icon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M7 7.99981L14.59 7.99981L6.29 16.2898C6.1017 16.4781 5.99591 16.7335 5.99591 16.9998C5.99591 17.2661 6.1017 17.5215 6.29 17.7098C6.4783 17.8981 6.7337 18.0039 7 18.0039C7.2663 18.0039 7.5217 17.8981 7.71 17.7098L16 9.40981L16 16.9998C16 17.265 16.1054 17.5194 16.2929 17.7069C16.4804 17.8945 16.7348 17.9998 17 17.9998C17.2652 17.9998 17.5196 17.8945 17.7071 17.7069C17.8946 17.5194 18 17.265 18 16.9998L18 6.99981C17.9984 6.86914 17.9712 6.74004 17.92 6.61982C17.8185 6.37547 17.6244 6.18129 17.38 6.07981C17.2598 6.02857 17.1307 6.0014 17 5.99981L7 5.99981C6.73478 5.99981 6.48043 6.10517 6.29289 6.29271C6.10536 6.48024 6 6.7346 6 6.99981C6 7.26503 6.10536 7.51939 6.29289 7.70692C6.48043 7.89446 6.73478 7.99981 7 7.99981Z" fill="#C59669"/>
        </g>
    </svg>
);

export const NewsArticle: FC<INewsArticleProps> = ({ title, url }) => {

  return (
        <article>
            <Link className={styles.container} href={url ?? ''} scroll={true}>
                <span className={styles.title}>{title}</span>
                <span className={styles.icon}>
                    <Icon />
                </span>
            </Link>
        </article>
  );
};