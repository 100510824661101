'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './news.module.scss';
import { AppRoutes } from '@/appConstants';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Button } from '@/shared/ui/button';
import { NewsArticle } from '@/shared/ui/news-article';
import { NewsTab } from '@/shared/ui/news-tab';

export const News = () => {
  const news = useHomePageStore()?.news ?? [];
  const stocks = useHomePageStore()?.stocks ?? [];
  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={classNames(styles.container, 'mb-170')} {...props}>
                    <NewsTab withPaddings tabNames={['Акции', 'Новости']} actionButton={<Button href={AppRoutes.stocks} type="secondary">смотреть все акции</Button>}>
                        {(activeIndex) => (
                            <>
                                <div className={classNames(styles.articles, activeIndex === 0 && styles.active)}>
                                    {stocks.items?.map(({ title, link }, index) => (
                                        <NewsArticle key={index} title={title} url={link} />
                                    ))}
                                </div>
                                <div className={classNames(styles.articles, activeIndex === 1 && styles.active)}>
                                    {news.items?.map(({ title, link }, index) => (
                                        <NewsArticle key={index} title={title} url={link} />
                                    ))}
                                </div>
                            </>
                        )}
                    </NewsTab>
                </motion.section>
            )}
        </AppearAnimation>
  );
};