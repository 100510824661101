'use client';

import { FC, useState } from 'react';
import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import styles from './news-tab.module.scss';
import { NewsTabsProps } from '@/shared/ui/news-tab/interfaces';

export const NewsTab: FC<NewsTabsProps> = ({ children, tabNames, actionButton, withPaddings, isTabsLinks }) => {
  const pathname = usePathname();
  const router = useRouter();
  const [activeTabIndex, setActiveTabIndex] = useState(() => {
    if (isTabsLinks) {
      return tabNames.findIndex(item => pathname.includes(item.link));
    }

    return 0;
  });

  const onChangeTabHandler = (tabIndex: number) => setActiveTabIndex(tabIndex);

  return (
        <div className={styles.container}>
            <div className={classNames(styles.top, withPaddings && styles.paddings)}>
                <div className={styles.tabs}>
                    {tabNames.map((tab, index) => {

                      if (typeof tab === 'string') {
                        return <button key={index} onClick={() => onChangeTabHandler(index)} className={classNames(styles.tab, index === activeTabIndex && `${styles.active}`)}>{tab}</button>;
                      }

                      return (
                            <button key={index} onClick={() => router.push(tab.link)} className={classNames(styles.tab, index === activeTabIndex && `${styles.active}`)}>{tab.title}</button>
                      );
                    })}
                </div>
                <div className={styles.desktop}>
                    {actionButton}
                </div>
            </div>
            <div>{children(activeTabIndex)}</div>
            <div className={styles.mobile}>{actionButton}</div>
        </div>
  );
};