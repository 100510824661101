import(/* webpackMode: "eager" */ "/www/node_modules/@effector/next/dist/index.js");
import(/* webpackMode: "eager" */ "/www/src/entities/global-footer/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/header/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/apartments/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/architecture/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/complexes/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/contact-form/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/contacts-home/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/counts/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/embankment-paragraph/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/embankment-slider/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/form-with-map/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/home-features/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/home-hero/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/infrastructure/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/lobby/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/news/index.tsx");
import(/* webpackMode: "eager" */ "/www/src/entities/home/parks/index.tsx")