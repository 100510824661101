'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './parks.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Button } from '@/shared/ui/button';
import { Carousel } from '@/shared/ui/carousel';
import { Typography } from '@/shared/ui/typography';
import { getSliderWithAlt } from '@/utils/getSliderWithAlt';

export const Parks = () => {
  const { title, more, slider, iconDescription, icon } = useHomePageStore()?.waterParks ?? {};

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={classNames(styles.section, 'container', 'mb-170')} {...props}>
                    <div className="desktop_slider">
                        <Carousel
                            hasThumbnails
                            childSide="right"
                            type="fullwidth"
                            items={getSliderWithAlt(slider, title)}
                        >
                            <div className={styles.container}>
                                <header className={styles.header}>
                                    <Typography as="h2" variant="h2">{title}</Typography>
                                    <Button type="secondary" href={more}>смотреть все виды</Button>
                                </header>
                                <div className={styles.bottom}>
                                    <picture>
                                      {icon && (
                                        <img className={styles.image} loading="lazy" src={icon} alt="Green Zoom" />
                                      )}
                                    </picture>
                                    <div className={styles.text}>
                                        <Typography>
                                            {iconDescription}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                    <div className="mobile_slider">
                        <div className={styles.container}>
                            <header className={styles.header}>
                                <Typography as="h2" variant="h2">{title}</Typography>
                                <Button type="secondary" href={more}>смотреть все виды</Button>
                            </header>
                            <div className={styles.bottom}>
                                <picture>
                                    {icon && (
                                        <img className={styles.image} loading="lazy" src={icon} alt="Green Zoom" />
                                    )}
                                </picture>
                                <div className={styles.text}>
                                    <Typography>
                                        {iconDescription}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Carousel
                            type="fullwidth"
                            items={getSliderWithAlt(slider, title)}
                        />
                    </div>
                </motion.section>
            )}
        </AppearAnimation>
  );
};