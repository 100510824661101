'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './counts.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Information } from '@/shared/ui/information';
import { Typography } from '@/shared/ui/typography';

export const Counts = () => {
  const advantages = useHomePageStore()?.waterParks?.advantages;

  if (!advantages) {
    return  null;
  }

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={classNames(styles.container, 'container', 'mb-170')} {...props}>
                    <Information>
                        {advantages?.map((advantage) => (
                            <Typography key={advantage} as="h3" variant="h3">{advantage}</Typography>
                        ))}
                    </Information>
                </motion.section>
            )}
        </AppearAnimation>
  );
};