'use client';

import { useRef } from 'react';
import { useInView } from 'framer-motion';
import dynamic from 'next/dynamic';
import styles from './home-hero.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { Typography } from '@/shared/ui/typography';

const VideoJS = dynamic(() => import('@/widgets/videojs').then(module => module.VideoJS), { ssr: false });

export const HomeHero = () => {
  const homeHero = useHomePageStore()?.promo;
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  return (
        <section className={styles.container}>
            <div ref={ref} className={styles.background}>
                {homeHero?.video && isInView && (
                    <VideoJS
                        autoplay
                        muted
                        src={homeHero.video}
                        onReady={(player) => {
                          player.loop(true);
                          player.playsinline(true);
                          player.play();
                        }}
                    />
                )}
            </div>
            <div className={styles.box}>
                <header className={styles.title}>
                    <Typography as="h1" variant="h1">
                        {homeHero?.title}
                    </Typography>
                </header>
                <Typography as="p" variant="p">
                    {homeHero?.titleDescription}
                </Typography>
            </div>
            <div className={styles.description}>
                <Typography as="p" variant="h4">
                    {homeHero?.description}
                </Typography>
            </div>
        </section>
  );
};