'use client';

import { motion } from 'framer-motion';
import styles from './lobby.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Button } from '@/shared/ui/button';
import { Carousel } from '@/shared/ui/carousel';
import { getSliderWithAlt } from '@/utils/getSliderWithAlt';
import { SectionHeader } from '@/widgets/section-header';

export const Lobby = () => {
  const { title, slider, more } = useHomePageStore()?.statusLobbie ?? {};

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={styles.container} {...props}>
                    <SectionHeader title={title ?? ''}>
                        <Button type="secondary" href={more}>узнать больше</Button>
                    </SectionHeader>
                    <Carousel
                        type="parallax"
                        items={getSliderWithAlt(slider, title)}
                    />
                </motion.section>
            )}
        </AppearAnimation>
  );
};