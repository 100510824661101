'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './home-features.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Card } from '@/shared/ui/card';
import { CardList } from '@/shared/ui/card-list';

export const HomeFeatures = () => {
  const advantages = useHomePageStore()?.promo?.advantages;

  return (
        <AppearAnimation>
            {({ animate, transition, variants, ref, initial }) => (
                <motion.section
                    ref={ref}
                    variants={variants}
                    transition={transition}
                    animate={animate}
                    initial={initial}
                    className={classNames(styles.container)}
                >
                    {advantages && (
                        <CardList>
                            {advantages.map(advantage => (
                                <Card key={advantage}>{advantage}</Card>
                            ))}
                        </CardList>
                    )}
                </motion.section>
            )}
        </AppearAnimation>
  );
};