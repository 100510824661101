'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './infrastructure.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Button } from '@/shared/ui/button';
import { Carousel } from '@/shared/ui/carousel';
import { Paragraph } from '@/shared/ui/paragraph';
import { Typography } from '@/shared/ui/typography';
import { getSliderWithAlt } from '@/utils/getSliderWithAlt';

export const Infrastructure = () => {
  const { title, slider, more, description } = useHomePageStore()?.landscapingInfrastructure ?? {};

  return (
        <section className={classNames(styles.section, 'container')}>
            <AppearAnimation>
                {(props) => (
                    <motion.div className={styles.header} {...props}>
                        <div className="desktop_slider">
                          <Carousel
                            hasThumbnails
                            type="fullwidth"
                            items={getSliderWithAlt(slider, title)}
                          >
                            <header className={styles.container}>
                                <Typography as="h2" variant="h2">{title}</Typography>
                                <div className={styles.button}>
                                    <Button type="secondary" href={more}>узнать больше</Button>
                                </div>
                            </header>
                          </Carousel>
                        </div>
                        <div className="mobile_slider">
                          <header className={styles.container}>
                            <Typography as="h2" variant="h2">{title}</Typography>
                            <div className={styles.button}>
                                <Button type="secondary" href={more}>узнать больше</Button>
                            </div>
                          </header>
                          <Carousel
                            type="fullwidth"
                            items={getSliderWithAlt(slider, title)}
                          />

                          <style>{`
                            .mobile_slider header{
                              margin-bottom: 50px;
                            }
                          `}</style>
                        </div>
                    </motion.div>
                )}
            </AppearAnimation>
            {description && (
                <AppearAnimation>
                    {(props) => (
                        <motion.div {...props}>
                            <Paragraph>
                                {description}
                            </Paragraph>
                        </motion.div>
                    )}
                </AppearAnimation>
            )}
        </section>
  );
};