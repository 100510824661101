import { FC } from 'react';
import styles from './section-header.module.scss';
import { Typography } from '@/shared/ui/typography';
import { ISectionHeaderProps } from '@/widgets/section-header/interfaces';

export const SectionHeader: FC<ISectionHeaderProps> = ({ title, children }) => {

  return (
        <header className="container">
            <div className={styles.container}>
                <div className={styles.title}>
                    <Typography as="h2" variant="h2">{title}</Typography>
                </div>
                <div className={styles.button}>
                    {children}
                </div>
            </div>
        </header>
  );
};