'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './embankment-paragraph.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Paragraph } from '@/shared/ui/paragraph';

export const EmbankmentParagraph = () => {
  const description = useHomePageStore()?.privateEmbankment?.description;

  if (!description) {
    return null;
  }

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section
                    className={classNames(styles.container, 'mb-170')}
                    {...props}
                >
                    {description && (
                        <Paragraph>
                            {description}
                        </Paragraph>
                    )}
                </motion.section>
            )}
        </AppearAnimation>
  );
};