'use client';

import { motion } from 'framer-motion';
import styles from './appartments.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Button } from '@/shared/ui/button';
import { Card } from '@/shared/ui/card';
import { CardList } from '@/shared/ui/card-list';
import { Carousel } from '@/shared/ui/carousel';
import { getSliderWithAlt } from '@/utils/getSliderWithAlt';
import { SectionHeader } from '@/widgets/section-header';

export const Apartments = () => {
  const { title, advantages, slider, more } = useHomePageStore()?.luxuryApartments ?? {};

  return (
        <section className={styles.container}>
            <AppearAnimation>
                {(props) => (
                    <motion.div className={styles.header} {...props}>
                        <SectionHeader title={title ?? ''}><Button type="secondary" href={more}>Узнать больше</Button></SectionHeader>
                        <Carousel
                            type="fullwidth"
                            items={getSliderWithAlt(slider, title)}
                        />
                    </motion.div>
                )}
            </AppearAnimation>
            <AppearAnimation>
                {(props) => (
                    <motion.div className="container" {...props}>
                        {advantages && (
                            <CardList>
                                {advantages.map(advantage => (
                                    <Card key={advantage}>
                                        {advantage}
                                    </Card>
                                ))}
                            </CardList>
                        )}
                    </motion.div>
                )}
            </AppearAnimation>
        </section>
  );
};