'use client';
import { useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Iframe from 'react-iframe';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import styles from './complexes.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Button } from '@/shared/ui/button';
import { ItemCard } from '@/shared/ui/item-card';
import { Typography } from '@/shared/ui/typography';

const Icon = () => (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 17.2225L24.09 17.2225L15.79 25.5125C15.6017 25.7008 15.4959 25.9562 15.4959 26.2225C15.4959 26.4888 15.6017 26.7442 15.79 26.9325C15.9783 27.1208 16.2337 27.2266 16.5 27.2266C16.7663 27.2266 17.0217 27.1208 17.21 26.9325L25.5 18.6325L25.5 26.2225C25.5 26.4877 25.6054 26.742 25.7929 26.9296C25.9804 27.1171 26.2348 27.2225 26.5 27.2225C26.7652 27.2225 27.0196 27.1171 27.2071 26.9296C27.3946 26.742 27.5 26.4877 27.5 26.2225L27.5 16.2225C27.4984 16.0918 27.4712 15.9627 27.42 15.8425C27.3185 15.5981 27.1244 15.4039 26.88 15.3025C26.7598 15.2512 26.6307 15.2241 26.5 15.2225L16.5 15.2225C16.2348 15.2225 15.9804 15.3278 15.7929 15.5154C15.6054 15.7029 15.5 15.9573 15.5 16.2225C15.5 16.4877 15.6054 16.742 15.7929 16.9296C15.9804 17.1171 16.2348 17.2225 16.5 17.2225Z" fill="#C59669"/>
    </svg>
);

export const Complexes = () => {
  const complexesArray = useHomePageStore()?.lsrGroup;

  const { title, link } = useHomePageStore()?.lsrGroupLink ?? {};
  const complexes = complexesArray?.length ? [...complexesArray] : [];

  const complexFirst = complexes?.shift();

  
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);

  const [opened, setOpenVideo] = useState(false);

  const onOpenModalVideo = () => setOpenVideo(true);

  const [activeTab, setActiveTab] = useState('Дом 8');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };


  return (
        <section className={classNames(styles.section, 'container', 'mb-170')}>
            <AppearAnimation>
                {(props) => (
                    <motion.div className={styles.complexes} {...props}>
                        {complexFirst && (
                            <ItemCard
                                className={styles.big}
                                poster={complexFirst.slide}
                                url={complexFirst.link}
                                subtitle="Жилой комплекс"
                                title={complexFirst.title}
                                labelText={complexFirst.sale ? 'Последние видовые квартиры' : undefined}
                            />
                        )}
                        <div className={styles.right}>
                            <header className={styles.header}>
                                <Typography as="h2" variant="h2">
                                    {title}
                                </Typography>
                                <Button type="secondary" href={link}>
                                    узнать больше
                                </Button>
                            </header>
                            <div className={styles.articles}>
                                <div className={styles.mobile}>
                                    {complexFirst && (
                                        <ItemCard
                                            className={classNames(styles.article)}
                                            poster={complexFirst.slide}
                                            url={complexFirst.link}
                                            subtitle="Жилой комплекс"
                                            title={complexFirst.title}
                                            labelText={complexFirst.sale ? 'Последние видовые квартиры' : undefined}
                                        />
                                    )}
                                </div>
                                {complexes?.map((complex, index) => (
                                    <ItemCard
                                        key={index}
                                        className={styles.article}
                                        poster={complex.slide}
                                        url={complex.link}
                                        subtitle="Жилой комплекс"
                                        title={complex.title}
                                        labelText={complex.sale ? 'Последние видовые квартиры' : undefined}
                                    />
                                ))}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AppearAnimation>
            <AppearAnimation>
                {(props) => (
                    <motion.div className={styles.padding} {...props}>
                        <div className={styles.buttons_wrapper}>
                            <Button  onClick={onOpenModalVideo} className={styles.video_button} type="thin" icon={<Icon />}>Видео хода строительства </Button>
                            <Button  onClick={onOpenModal} className={styles.button} type="thin" icon={<Icon />}>Cмотреть ход строительства online </Button>
                        </div>
                        <Modal
                        open={opened}
                        onClose={() => setOpenVideo(false)}
                        center
                        classNames={{
                          closeButton: 'progressClose',
                          overlay: 'progressOverlay',
                          modal: 'progressModal videoModal',
                        }}
                        >
                            <div className="modal-wrapper">
                                <Iframe url="https://www.youtube.com/embed/QNc9Koq3P94?si=tJpuigPFp03RPmoO" allowFullScreen={true} frameBorder={0}/>
                            </div>
                        </Modal>
                        
                        <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        center
                        classNames={{
                          closeButton: 'progressClose',
                          overlay: 'progressOverlay',
                          modal: 'progressModal',
                        }}
                        >
                            <div className="modal-wrapper">
                                <h2 className='raleway'>Прямая трансляция строительства</h2>
                                <div className="row">
                                <div className="tab-nav-list">
                                    {['Дом 8', 'Дом 7', 'Дом 6', 'Дом 5', 'Дом 4', 'Дом 3'].map((tab) => (
                                    <div
                                        key={tab}
                                        className={`tab-nav-item ${tab === activeTab ? 'active' : ''}`}
                                        onClick={() => handleTabClick(tab)}
                                    >
                                        {tab}
                                    </div>
                                    ))}
                                </div>
                                <div className="tab-nav-content">
                                
                                    {activeTab === 'Дом 8' && <div className='iframe-wrapper'><Iframe url="https://webcam.exdesign.ru/neva-haus-8/?fluid" allowFullScreen={true} frameBorder={0}/></div>}
                                    {activeTab === 'Дом 7' && <div className='iframe-wrapper'><Iframe url="https://webcam.exdesign.ru/neva-haus-7/?fluid" allowFullScreen={true} frameBorder={0}/></div>}
                                    {activeTab === 'Дом 6' && <div className='iframe-wrapper'><Iframe url="https://webcam.exdesign.ru/neva-haus-6/?fluid" allowFullScreen={true} frameBorder={0}/></div>}
                                    {activeTab === 'Дом 5' && <div className='iframe-wrapper'><Iframe url="https://webcam.exdesign.ru/neva-haus-2/?fluid" allowFullScreen={true} frameBorder={0}/></div>}
                                    {activeTab === 'Дом 4' && <div className='iframe-wrapper'><Iframe url="https://webcam.exdesign.ru/neva-haus-3/?fluid" allowFullScreen={true} frameBorder={0}/></div>}
                                    {activeTab === 'Дом 3' && <div className='iframe-wrapper'><Iframe url="https://webcam.exdesign.ru/neva-haus-5/?fluid" allowFullScreen={true} frameBorder={0}/></div>}
                                </div>
                                </div>
                            </div>
                        </Modal>
                        
                    </motion.div>
                )}
            </AppearAnimation>
        </section>
  );
};