'use client';

import { FC } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './item-card.module.scss';
import { Image } from '@/shared/ui/image';
import { IItemCardProps } from '@/shared/ui/item-card/interfaces';
import { Typography } from '@/shared/ui/typography';

export const ItemCard: FC<IItemCardProps> = ({ url, subtitle, title, poster, labelText, labelType = 'gold', className }) => {
  if (url === '/') {
    return (
          <div className={classNames(styles.container, className)}>
              <div className={styles.poster}>
                  {labelText && (
                      <div className={classNames(styles.label, labelType === 'gold' && styles['label--golden'], labelType === 'green' && styles['label--green'])}>
                          {labelText}
                      </div>
                  )}
                  {poster && (
                      <Image
                          src={poster}
                          alt={`${subtitle} ${title}`}
                      />
                  )}
              </div>
              <header>
                  <Typography as="h3" variant="h3">
                      {subtitle}
                  </Typography>
                  <Typography as="h3" variant="h3">
                      {title}
                  </Typography>
              </header>
          </div>
    );
  } else {
    return (
      <Link target="_blank" href={url} className={classNames(styles.container, className)}>
          <div className={styles.poster}>
              {labelText && (
                  <div className={classNames(styles.label, labelType === 'gold' && styles['label--golden'], labelType === 'green' && styles['label--green'])}>
                      {labelText}
                  </div>
              )}
              {poster && (
                  <Image
                      src={poster}
                      alt={`${subtitle} ${title}`}
                  />
              )}
          </div>
          <header>
              <Typography as="h3" variant="h3">
                  {subtitle}
              </Typography>
              <Typography as="h3" variant="h3">
                  {title}
              </Typography>
          </header>
      </Link>
    );
  }
};