'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Contacts } from '@/widgets/page/contacts';

export const ContactsHome = () => {
  const { officeAddress, phone, salesAddress, salesWorkTime, officeWorkTime } = useHomePageStore().contacts ?? {};

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={classNames('container', 'mb-170')} {...props}>
                    <Contacts phone={phone ?? ''} salesWorkTime={salesWorkTime ?? []} salesAddress={salesAddress ?? ''} officeAddress={officeAddress ?? ''} officeWorkTime={officeWorkTime ?? []} />
                </motion.section>
            )}
        </AppearAnimation>
  );
};