
'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './embankment-slider.module.scss';
import { useHomePageStore } from '@/features/home/hooks';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { Button } from '@/shared/ui/button';
import { Carousel } from '@/shared/ui/carousel';
import { Typography } from '@/shared/ui/typography';
import { getSliderWithAlt } from '@/utils/getSliderWithAlt';

export const EmbankmentSlider = () => {
  const { title, slider, more } = useHomePageStore()?.privateEmbankment ?? {};

  return (
        <AppearAnimation>
            {(props) => (
                <motion.section className={classNames(styles.container)} {...props}>
                    <header className={classNames(styles.top, 'container')}>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                <Typography as="h2" variant="h2">{title}</Typography>
                            </div>
                            <div className={styles.button}>
                                <Button type="secondary" href={more}>Узнать больше</Button>
                            </div>
                        </div>
                    </header>
                    <Carousel
                        type="fullwidth"
                        items={getSliderWithAlt(slider, title)}
                    />
                </motion.section>
            )}
        </AppearAnimation>
  );
};